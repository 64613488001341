html,
body {
  height: 100%;
  margin: 0;
}

/* Reset default margin and padding */
body {
  margin: 0;
  padding: 0;
  height: 100%;
}

/* Set box-sizing to border-box for all elements */
*,
*::before,
*::after {
  box-sizing: border-box;
}

/* Set a default font family , color*/
body {
  font-family: Arial, sans-serif;
  color: #303333;
  background-color: #F8FBFD !important;
}

.k-grid-pager {
  justify-content: flex-end !important;
}
.cursor{
  cursor: pointer;
}
.k-menu-group .k-item>.k-link:active,
.k-menu-group .k-item>.k-link.k-active,
.k-menu-group .k-item>.k-link.k-selected,
.k-menu.k-context-menu .k-item>.k-link:active,
.k-menu.k-context-menu .k-item>.k-link.k-active,
.k-menu.k-context-menu .k-item>.k-link.k-selected {
    color: #fff;
    background-color: #5F7F99 !important;
}
.k-radio:checked,
.k-radio.k-checked {
    border-color: #5F7F99;
    color: #fff;
    background-color: #5F7F99
}


.k-bg-primary {
  background-color: #5F7F99 !important;
}